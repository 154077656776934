import styled from "styled-components";
import { Container } from "../common/container";
import { breakpoints } from "../constants/breakpoints";

export const Custom404Container = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;

  @media ${breakpoints.tablet} {
    padding: 100px 30px;
  }
`;

export const Custom404Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  line-height: 1.25;
  margin: 16px 0;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
  }
`;

export const Custom404Desc = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.neutral[600]};
  line-height: 1.25;
  margin: 0;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

export const Custom404ImageWrapper = styled.figure`
  margin: 16px 0;
`;
