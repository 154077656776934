import React from "react";
import Image from "next/legacy/image";
import NecoTangled from "public/assets/neco_tangled.png";

import {
  Custom404Container,
  Custom404Desc,
  Custom404ImageWrapper,
  Custom404Title
} from "../styles/pages/custom404.styles";

const Custom404: React.FC = () => (
  <Custom404Container>
    <Custom404ImageWrapper>
      <Image
        src={NecoTangled}
        alt="Server down error"
        layout="fixed"
        width={240}
        height={240}
      />
    </Custom404ImageWrapper>
    <Custom404Title>Yah, Neco nyangkut di halaman ini.</Custom404Title>
    <Custom404Desc>
      Maaf ya, CoFriends. Halamannya nggak ketemu, nih.
    </Custom404Desc>
  </Custom404Container>
);

export default Custom404;
